import { sendCertificateEmail } from 'api/requests/insurance-certificate-requests';
import Button from 'components/button/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getCertificateRequestForm } from 'services/verification-step';
import { DATA_CHECK_STEP__TITLE, DATA_CHECK_STEP__VIN, DATA_CHECK_STEP__LICENSE_PLATE, DATA_CHECK_STEP__EMAIL, NEXT__BUTTON, MODIFY__BUTTON } from 'translations/constants';
import './style.scss';

const DataCheck = () => {
    const [t, i18next] = useTranslation();
    const history = useHistory();

    const [dataCheckValues, setDataCheckValues] = useState({
        ChassisNumber: '',
        LicensePlate: '',
        Email: ''
    });

    const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);

    const navigateToCertificateRequest = () => {
        history.push('/certificate-request-step');
    }

    const submitCertificateRequest = async () => {
        setNextButtonDisabled(true);
        
        const sendCertificateResponse = await sendCertificateEmail({
            ChassisNumber: dataCheckValues.ChassisNumber,
            LicensePlate: dataCheckValues.LicensePlate,
            Email: dataCheckValues.Email,
            LanguageCode: i18next.language,
        });

        if (sendCertificateResponse.success === true) {
            history.push('/email-verification');
        } else {
            history.push('/error');
        }

        setNextButtonDisabled(false);
    }

    useEffect(() => {
        const insuranceCertificateData = getCertificateRequestForm();
        setDataCheckValues(insuranceCertificateData);
    }, []);

    return (
        <div className="data-check__container">
            <h1 className="data-check__title">{t(DATA_CHECK_STEP__TITLE)}</h1>
            <div className="data-check__user-data">
                <p className="data-check__label">
                    {t(DATA_CHECK_STEP__VIN)}:&nbsp;{dataCheckValues.ChassisNumber.substring(0, 3)}&nbsp;{dataCheckValues.ChassisNumber.substring(3, 6)}
                </p>
                <p className="data-check__label">
                    {t(DATA_CHECK_STEP__LICENSE_PLATE)}:&nbsp;{dataCheckValues.LicensePlate}
                </p>
                <p className="data-check__label">
                    {t(DATA_CHECK_STEP__EMAIL)}:&nbsp;{dataCheckValues.Email}</p>
            </div>
            <div className="button--display">
                <Button text={t(MODIFY__BUTTON)} disabled={false} onClick={navigateToCertificateRequest} large={false} styleClass="data-check__button--margin" />
                <Button
                    text={t(NEXT__BUTTON)}
                    disabled={isNextButtonDisabled}
                    onClick={submitCertificateRequest}
                    large={false}
                />
            </div>
        </div>
    );
};

export default DataCheck;