import { useTranslation } from 'react-i18next';
import { CHECK_EMAIL__MESSAGE } from 'translations/constants';
import './style.scss';

const EmailVerification = () => {
  const [t] = useTranslation();

  return (
    <div className="email_verification__container">
      <p className="email_verification__text">{t(CHECK_EMAIL__MESSAGE)}</p>
    </div>
  );
};

export default EmailVerification;
