import { useTranslation } from 'react-i18next';
import { BACK__BUTTON, GENERIC_ERROR_PAGE__MESSAGE } from 'translations/constants';
import Button from 'components/button';
import './style.scss';

const GenericErrorPage = () => {
  const routeChange = () => {
    window.location.href = '/';
  };

  const [t] = useTranslation();
  return (
    <div className="generic_error__container">
      <p className="error__text--margin-bottom">{t(GENERIC_ERROR_PAGE__MESSAGE)}</p>
      <Button text={t(BACK__BUTTON)} disabled={false} onClick={routeChange} />
    </div>
  );
};
export default GenericErrorPage;
