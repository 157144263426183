import { ReactNode } from 'react';
import './style.scss';

type AppContainerProps = {
  children: ReactNode;
};

const AppContainer = (props: AppContainerProps) => {
  const { children } = props;
  return <div className="app-container">{children}</div>;
};

export default AppContainer;
