import _ from 'lodash';
import ILanguageResponse from 'types/responses/ILanguageResponse';

const languageMapper = (languageData: ILanguageResponse[]) => {
  return _.map(languageData, (x) => ({
    id: _.get(x, 'id', 0),
    name: _.get(x, 'name', ''),
    languageCode: _.get(x, 'languageCode', ''),
  }));
};

export default languageMapper;
