import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import CertificateRequestStep from 'modules/certificate-request';
import ErrorScreen from 'modules/error-screen';
import EmailVerification from 'modules/email-verification';
import GenericErrorPage from 'modules/error-page';
import DataCheck from 'modules/data-check';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/certificate-request-step">
          <CertificateRequestStep />
        </Route>
        <Route path="/data-check-step">
          <DataCheck />
        </Route>
        <Route path="/error">
          <ErrorScreen />
        </Route>
        <Route path="/email-verification">
          <EmailVerification />
        </Route>
        <Route path="/error-response-page">
          <GenericErrorPage />
        </Route>
        <Route path="/">
          <Redirect to="/certificate-request-step" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
