export const NEXT__BUTTON = '[NEXT] BUTTON';
export const VERIFICATION_STEP__VIN = '[VERIFICATION_STEP] VIN';
export const VERIFICATION_STEP__LICENSE_PLATE = '[VERIFICATION_STEP] LICENSE_PLATE';
export const VERIFICATION_STEP__EMAIL = '[VERIFICATION_STEP] EMAIL';
export const VERIFICATION_STEP__TITLE = '[VERIFICATION_STEP] TITLE';
export const VERIFICATION_STEP__INSTRUCTIONS = '[VERIFICATION_STEP] INSTRUCTIONS';
export const BACK__BUTTON = '[BACK] BUTTON';
export const ERROR__MESSAGE = '[ERROR] MESSAGE';
export const ERROR__TITLE = '[ERROR] TITLE';

export const PERSONAL_DATA__EMAIL = '[PERSONAL_DATA] EMAIL';
export const PERSONAL_DATA__NAME = '[PERSONAL_DATA] NAME';
export const PERSONAL_DATA__NAME_PLACEHOLDER = '[PERSONAL_DATA] NAME_PLACEHOLDER';
export const PERSONAL_DATA__LAST_NAME = '[PERSONAL_DATA] LAST_NAME';
export const PERSONAL_DATA__LAST_NAME_PLACEHOLDER = '[PERSONAL_DATA] LAST_NAME_PLACEHOLDER';
export const PERSONAL_DATA__PHONE_NUMBER = '[PERSONAL_DATA] PHONE_NUMBER';
export const PERSONAL_DATA__DATE_OF_BIRTH = '[PERSONAL_DATA] DATE_OF_BIRTH';
export const PERSONAL_DATA__DATE_OF_BIRTH_PLACEHOLDER = '[PERSONAL_DATA] DATE_OF_BIRTH_PLACEHOLDER';
export const PERSONAL_DATA__ADDRESS = '[PERSONAL_DATA] ADDRESS';
export const PERSONAL_DATA__ADDRESS_PLACEHOLDER = '[PERSONAL_DATA] ADDRESS_PLACEHOLDER';
export const PERSONAL_DATA__ADDRESS_NUMBER = '[PERSONAL_DATA] ADDRESS_NUMBER';
export const PERSONAL_DATA__ADDRESS_NUMBER_PLACEHOLDER = '[PERSONAL_DATA] ADDRESS_NUMBER_PLACEHOLDER';
export const PERSONAL_DATA__ZIP_CODE = '[PERSONAL_DATA] ZIP_CODE';
export const PERSONAL_DATA__ZIP_CODE_PLACEHOLDER = '[PERSONAL_DATA] ZIP_CODE_PLACEHOLDER';
export const PERSONAL_DATA__CITY = '[PERSONAL_DATA] CITY';
export const PERSONAL_DATA__CITY_PLACEHOLDER = '[PERSONAL_DATA] CITY_PLACEHOLDER';
export const PERSONAL_DATA__BUS = '[PERSONAL_DATA] BUS';
export const PERSONAL_DATA__BUS_PLACEHOLDER = '[PERSONAL_DATA] BUS_PLACEHOLDER';

export const DATA_CHECK_STEP__TITLE = '[DATA_CHECK_STEP] TITLE';
export const DATA_CHECK_STEP__VIN = '[DATA_CHECK_STEP] VIN';
export const DATA_CHECK_STEP__LICENSE_PLATE = '[DATA_CHECK_STEP] LICENSE_PLATE';
export const DATA_CHECK_STEP__EMAIL = '[DATA_CHECK_STEP] EMAIL';
export const MODIFY__BUTTON = '[MODIFY] BUTTON';

export const CHECK_EMAIL__MESSAGE = '[CHECK_EMAIL] MESSAGE';
export const GENERIC_ERROR_PAGE__MESSAGE = 'GENERIC ERROR MESSAGE';

export const ERROR_MESSAGE__REQUIRED = '[ERROR_MESSAGE] REQUIRED';
export const ERROR_MESSAGE__INVALID = '[ERROR_MESSAGE] INVALID';
