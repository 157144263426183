import clsx from 'clsx';
import './style.scss';

type Props = {
  text: string;
  disabled: boolean;
  onClick: any;
  large?: boolean;
  styleClass?: string;
};

const Button = (props: Props) => {
  const { text, disabled, onClick, large, styleClass } = props;
  const buttonClassName = clsx('button__primary', { 'button--large': large }, { 'button--small': !large }, styleClass);

  return (
    <button type="button" className={buttonClassName} disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
