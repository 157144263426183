import { getConfig } from 'config';
import i18next, { InitOptions } from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import log from 'utils/log';

const options: InitOptions = {
  fallbackLng: 'nl',
  lng: 'nl',
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
};

export const initTranslations = async (): Promise<void> => {
  try {
    i18next.use(initReactI18next);
    i18next.use(HttpApi);
    await i18next.init({ ...options, backend: { loadPath: `${getConfig('API_BASE_URL')}translations/GetAllTranslations/{{lng}}` } });
  } catch (err) {
    log(err);
  }
};
