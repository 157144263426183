import { getApi } from 'api';
import axios, { AxiosResponse } from 'axios';
import languageMapper from 'mappers/language-mapper';
import ILanguageResponse from 'types/responses/ILanguageResponse';
import log from 'utils/log';

export const getLanguages = async (): Promise<ILanguageResponse[]> => {
  try {
    const response: AxiosResponse<ILanguageResponse[]> = await getApi().get('/translations/GetAllLanguages');
    return languageMapper(response.data);
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};

export const getTranslations = async (lang: string): Promise<Record<string, string>> => {
  try {
    const response: AxiosResponse = await getApi().get(`/translations/GetAllTranslations/${lang}`);
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};
