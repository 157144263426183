import { useTranslation } from 'react-i18next';
import { BACK__BUTTON, ERROR__TITLE, ERROR__MESSAGE } from 'translations/constants';
import Button from 'components/button';
import './style.scss';
import { useHistory } from 'react-router-dom';

const ErrorScreen = () => {
  const history = useHistory();

  const routeChange = () => {
    const path = `/verification-step`;
    history.push(path);
  };

  const [t] = useTranslation();
  return (
    <div className="error__container">
      <h1 className="error__title--format">{t(ERROR__TITLE)}</h1>
      <p className="error__text--no-margin">{t(ERROR__MESSAGE)}</p>
      <div className="contact_info">
        <p className="contact_info__text">+32 3 544 29 50</p>
        <a href="mailto:damage.be@internationalcarlease.com" className="contact_info__text">
          damage.be@internationalcarlease.com
        </a>
      </div>
      <Button text={t(BACK__BUTTON)} disabled={false} onClick={routeChange} />
    </div>
  );
};
export default ErrorScreen;
