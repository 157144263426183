import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import {
  VERIFICATION_STEP__TITLE,
  VERIFICATION_STEP__INSTRUCTIONS,
  VERIFICATION_STEP__LICENSE_PLATE,
  VERIFICATION_STEP__EMAIL,
  VERIFICATION_STEP__VIN,
  NEXT__BUTTON,
  ERROR_MESSAGE__INVALID,
  ERROR_MESSAGE__REQUIRED,
} from 'translations/constants';
import Button from 'components/button';
import Input from 'components/input';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getCertificateRequestForm, setCertificateRequestForm } from 'services/verification-step';
import ICertificateRequestForm from 'types/forms/ICertificateRequestForm';
import { getLanguages } from 'api/requests/translations-requests';
import Lang from 'types/Lang';
import Dropdown from 'components/dropdown';

const CertificateRequestStep = () => {
  const history = useHistory();
  const [t, i18next] = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languageOptions, setLanguageOptions] = useState([]);

  const requestLanguages = async () => {
    const languages = await getLanguages();
    if (languages) {
      setLanguageOptions(languages);
    }
  };

  const changeLanguageDropdown = (lang: Lang) => {
    setSelectedLanguage(lang);
    i18next.changeLanguage(lang);
  };

  const schema = yup.object().shape({
    ChassisNumber: yup.string().required(t(ERROR_MESSAGE__REQUIRED)).min(6, t(ERROR_MESSAGE__INVALID)).max(6, t(ERROR_MESSAGE__INVALID)),
    LicensePlate: yup.string().required(t(ERROR_MESSAGE__REQUIRED)),
    Email: yup.string().email(ERROR_MESSAGE__INVALID).required(t(ERROR_MESSAGE__REQUIRED)),
  });

  const [licensePlateInput, setLicensePlateInput] = useState('');
  const {
    handleSubmit,
    trigger,
    setValue,
    control,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<ICertificateRequestForm>({ resolver: yupResolver(schema), mode: 'onChange' });

  const appHandleSubmit = handleSubmit(async (data) => {
    setCertificateRequestForm({ ChassisNumber: data.ChassisNumber, LicensePlate: data.LicensePlate, Email: data.Email });
    history.push('/data-check-step');
  });

  const formatVinInput = (value: string) => `${value}`?.match(/[a-zA-Z0-9]+/g)?.join('').toUpperCase() || '';

  const formatLicensePlateInput = (e: any) => {
    const re = /^[a-zA-Z0-9\s]+$/;
    e.target.value = e.target.value.toUpperCase();
    if (e.target.value === '' || re.test(e.target.value)) {
      setLicensePlateInput(e.target.value);
    }
  };

  const formatEmailInput = (value: string) => value.toLowerCase() || '';

  useEffect(() => {
    requestLanguages();
  }, []);

  useEffect(() => {
    const insuranceCertificateData = getCertificateRequestForm();
    setLicensePlateInput(insuranceCertificateData.LicensePlate);
    setValue('ChassisNumber', insuranceCertificateData.ChassisNumber);
    setValue('LicensePlate', insuranceCertificateData.LicensePlate);
    setValue('Email', insuranceCertificateData.Email);
  }, []);

  return (
    <div className="verification__container">
      <form className="verification__form--full-height">
        <div className='verification__language-selector-container'>
          <Dropdown items={languageOptions} value={selectedLanguage} onChange={changeLanguageDropdown} />
        </div>
        <div>
          <h1 className="verification__title--format">{t(VERIFICATION_STEP__TITLE)}</h1>
          <p className="verification__instructions--format">{t(VERIFICATION_STEP__INSTRUCTIONS)}</p>
        </div>
        <div className="verification__inputs--center">
          <Controller
            control={control}
            name="ChassisNumber"
            defaultValue=""
            rules={{
              required: true,
              validate: () => {
                return true;
              },
            }}
            render={({ field: { name, value, onChange } }) => (
              <Input
                label={t(VERIFICATION_STEP__VIN)}
                fullWidth
                type="text"
                name={name}
                onChange={(ev) => onChange(formatVinInput(ev.target.value))}
                value={value}
                errors={errors.ChassisNumber}
                id="VIN-digits"
                placeholder=" ___  ___  ___  ___  ___  ___"
                isRequired
              />
            )}
          />

          <Controller
            control={control}
            name="LicensePlate"
            defaultValue=""
            rules={{
              required: true,
              validate: () => {
                return true;
              },
            }}
            render={({ field: { name, onChange } }) => (
              <Input
                label={t(VERIFICATION_STEP__LICENSE_PLATE)}
                fullWidth
                type="text"
                name={name}
                onChange={onChange}
                onInput={formatLicensePlateInput}
                value={licensePlateInput}
                errors={errors.LicensePlate}
                id="license-plate"
                placeholder=" ___  ___  ___  ___  ___  ___  ___"
                isRequired
              />
            )}
          />

          <Controller
            control={control}
            name="Email"
            defaultValue=""
            rules={{
              required: true,
              validate: () => {
                return true;
              },
            }}
            render={({ field: { name, value, onChange } }) => (
              <Input
                label={t(VERIFICATION_STEP__EMAIL)}
                fullWidth
                type="text"
                name={name}
                onChange={(ev) => onChange(formatEmailInput(ev.target.value))}
                value={value}
                errors={errors.Email}
                id="email"
                placeholder="email@address.com"
                isRequired
              />
            )}
          />
        </div>
        <div className="verification__button--center">
          <Button text={t(NEXT__BUTTON)} disabled={!isDirty || !isValid || isSubmitting} onClick={appHandleSubmit} large />
        </div>
      </form>
    </div>
  );
};

export default CertificateRequestStep;
