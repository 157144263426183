import { getApi } from 'api';
import axios, { AxiosResponse } from 'axios';
import IFormData from 'types/responses/IFormData';
import ISendCertificateEmailResponse from 'types/responses/ISendCertificateEmailResponse';
import log from 'utils/log';

export const sendCertificateEmail = async (data: IFormData): Promise<ISendCertificateEmailResponse> => {
  try {
    const response: AxiosResponse<ISendCertificateEmailResponse> = await getApi().post(`/insuranceCertificate/SendCertificateEmail`, data);
    return response.data;
  } catch (err: any) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};
