import { useState } from 'react';
import Lang from 'types/Lang';
import Button from '../button';
import './style.scss';

type Item = {
  name: string;
  languageCode: Lang;
};

type Props = {
  items: Item[];
  value: Lang;
  // eslint-disable-next-line no-unused-vars
  onChange: (lng: Lang) => void;
};

const defaultLabel = 'Taal/Langue >';

const Dropdown = (props: Props) => {
  const { items, value, onChange } = props;
  const [showList, setShowList] = useState(false);
  const [initialShow, setInitialShow] = useState(true);
  const selectedItem = items?.find((item) => item.languageCode === value);
  const label = initialShow ? defaultLabel : selectedItem.name;

  const changeButton = () => {
    setShowList(!showList);
  };

  const changeState = (lang: Lang) => {
    onChange(lang);
    setInitialShow(!showList);
    setShowList(!showList);
  };

  const listItems = items?.map((d) => (
    <div className="dropdown-list-item" role="button" tabIndex={0} key={d.languageCode} onClick={() => changeState(d.languageCode)} onKeyDown={() => changeState(d.languageCode)}>
      {d.name}
    </div>
  ));

  return (
    <div className="dropdown">
      <Button text={label} disabled={false} onClick={changeButton} large />
      {showList && <div className="dropdown-list">{listItems}</div>}
    </div>
  );
};

export default Dropdown;
