import axios from 'axios';
import { getConfig } from 'config';

let instance = axios.create({});

export const initApi = () => {
  instance = axios.create({
    baseURL: getConfig('API_BASE_URL'),
  });
};

export const getApi = () => instance;
