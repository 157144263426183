import loadApi from './load-api';
import loadConfig from './load-config';
import loadTranslations from './load-translations';

const loadInitial = async (): Promise<void> => {
  await loadConfig();
  await loadApi();
  await loadTranslations();
};

export default loadInitial;
