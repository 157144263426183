import clsx from 'clsx';
import { ChangeEvent } from 'react';
import './style.scss';
import ReactTooltip from 'react-tooltip';

type Props = {
  type: string;
  name: string;
  id: string;
  styleClass?: string;
  containerStyleClass?: string;
  label: string;
  fullWidth?: boolean;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  errors?: any;
  isRequired?: boolean;
};

const Input = (props: Props) => {
  const { type, name, id, styleClass, label, fullWidth, placeholder, containerStyleClass, onChange, onInput, onBlur, value, errors, isRequired } = props;
  const inputContainerClassName = clsx('input__container', { 'input__container--block': fullWidth }, containerStyleClass);

  return (
    <div className={inputContainerClassName}>
      <label className="input__label" htmlFor={id}>
        {label}
        <span className="input__required--red">{isRequired ? '*' : ''}</span>
        <input
          name={name}
          autoComplete="off"
          className={clsx('input', styleClass, { 'input--error': errors })}
          type={type}
          id={id}
          value={value}
          placeholder={placeholder}
          onInput={onInput}
          onChange={onChange}
          onBlur={onBlur}
          data-tip={errors ? errors.message : ''}
        />
      </label>
      <ReactTooltip place="bottom" type="error" effect="solid" />
    </div>
  );
};

export default Input;
