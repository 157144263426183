import AppRouter from './AppRouter';

const Router = () => {
  /**
   * You can add different routers here
   * based on authentication state or other conditions
   */
  return <AppRouter />;
};

export default Router;
